import Layout from "../../components/layout";
import PageTitle from "../../components/pagetitle";
import Seo from "../../components/seo";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

const MadisonPage = () => (
    <Layout>
        <PageTitle title="Managed Services and Software In Madison, VA" page="PageTitleAbout" />
        <Seo title="Managed Services and Software In Madison, VA"
             description="New Vertical offers Managed Services and Software services to Madison, Virginia a rural community in the Shenandoah Valley, located just 75 miles from the nation’s capital."
             pathname={"/locations/madison/"}/>
        <div className="content-page-wrapper">
            <div className="page-intro">
                <StaticImage
                    style={{
                        gridArea: "1/1",
                    }}
                    layout="fullWidth"
                    loading="eager"
                    aspectRatio={2 / 1}
                    alt="Our team will help"
                    src={"../../images/madison.jpg"}
                    formats={["auto", "jpg", "avif"]}
                />
                <h2>Managed Services and Software In Madison Virginia</h2>
                <p>
                    Madison, Virginia is a small rural community in the Shenandoah Valley, located just 75 miles from the nation’s capital. Despite its small size, Madison has a number of technology services that provide residents and businesses with specialty services and equipment.</p>

                <p> The first technology service in Madison is Madison IT Solutions, a full-service information technology and computer service provider. Located right at the heart of Madison, Madison IT Solutions offers laptop and desktop repair, virus removal, website and software development, and data recovery. Madison IT Solutions also provides 24/7 customer service, offering same-day service when possible.</p>

                <p> Another notable technology service in Madison is Isite Solutions. Located in Culpeper, VA, Isite Solutions offers network and infrastructure support, hardware and software installation, and cloud-based services. Isite Solutions also specializes in cyber security and helpdesk support for businesses, making sure that their customers’ computers and networks are always secure.</p>

                <p> For those interested in digital media development and graphic design, Madison has Digital Arts Dynamix, a full-service digital media studio. Digital Arts Dynamix is equipped with the latest tools and technology, offering services such as video production, graphic design, website design, and digital printing. The team of professionals at Digital Arts Dynamix are highly experienced, making sure each project is completed to the highest standards.</p>

                <p> Madison also has Madison Technical Group, an IT company that offers everything from IT services and computer repairs to home networking. Madison Technical Group provides services such as network installation and configuration, web development and hosting, and even phone and cable installation. The team at Madison Technical Group is trained in the latest technology, providing the best possible service to their clients.</p>

                <p> Finally, Madison has ATI Technologies, a technology services provider that offers a range of IT solutions. Whether you are looking to buy a new computer or upgrade an existing one, ATI Technologies offers the latest in computer parts and equipment. ATI Technologies also offers services such as installation, system optimization, and after-sales technical support.</p>

                <p> These are just a few of the technology services located in Madison, Virginia. While the community is small, residents and businesses have access to some of the highest-quality IT services in the area. Whether you need help with computer and network repairs, digital media development, or web hosting, Madison offers it all.</p>
            </div>
        </div>
    </Layout>
)

export default MadisonPage